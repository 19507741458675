import React, { useState, useEffect, useRef } from 'react';
import './shiftwork-splash.css'
import emailjs from '@emailjs/browser';
import BoltIcon from '@mui/icons-material/Bolt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import FestivalRoundedIcon from '@mui/icons-material/FestivalRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';






const TikTokIcon = () => {return (<svg fill="currentColor" width="20px" height="20px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>);};
const FacebookIcon = () => {return (<svg fill="currentColor" width="23px" height="23px" viewBox="-7 -2 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-facebook"><path d='M2.046 3.865v2.748H.032v3.36h2.014v9.986H6.18V9.974h2.775s.26-1.611.386-3.373H6.197V4.303c0-.343.45-.805.896-.805h2.254V0H6.283c-4.34 0-4.237 3.363-4.237 3.865z' /></svg>);};

const ShiftworkNav = ({ aboutRef, featuresRef, industriesRef, faqRef, signupRef, screenSize }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    
    const [modalTrayOpen, setModalTrayOpen] = useState(false);
    const handleOpen = () => setModalTrayOpen(true);
    const handleClose = () => setModalTrayOpen(false);
    


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    console.log(screenSize);

    const handleHomeClick = () => {
        handleClose();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleAboutClick = () => {
        if (aboutRef && aboutRef.current) {
            handleClose();
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleFeaturesClick = () => {
        if (featuresRef && featuresRef.current) {
            handleClose();
            featuresRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleIndustriesClick = () => {
        if (industriesRef && industriesRef.current) {
            handleClose();
            industriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleFAQClick = () => {
        if (faqRef && faqRef.current) {
            handleClose();
            faqRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleStartClick = () => {
        if (signupRef && signupRef.current) {
            handleClose();
            signupRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    

    return(<>
        {screenSize === "small" ? 
            (
                <>
                    <div className={`shiftwork-nav-mobile ${isScrolled ? 'scrolled' : ''}`}>
                        <div className={`shiftwork-nav-inner-mobile ${isScrolled ? 'scrolled' : ''}`}>
                            <div className={`shiftwork-nav-logo-mobile ${isScrolled ? 'scrolled' : ''}`}>
                                <span onClick={handleHomeClick}>SH<BoltIcon style={{fontSize: isScrolled ? "30px" : "40px", marginBottom: isScrolled ? "-3px":"-5px", marginLeft: isScrolled ? "-8px":"-11px", marginRight: isScrolled ?"-8px":"-13px"}}/>FTWORK</span>
                                {!isScrolled && <div className='shiftwork-nav-subtitle-mobile'>Speed and Talent - Lightning Fast</div>}
                            </div>
                            <button onClick={handleOpen} className={`shiftwork-nav-tray-mobile ${isScrolled ? 'scrolled' : ''}`}><MenuIcon style={{ fontSize: isScrolled ? "30px" : "30px" }} /></button>
                        </div>
                    </div>
                    <Modal open={modalTrayOpen} onClose={handleClose}>
                        <Slide direction="down" in={modalTrayOpen} mountOnEnter unmountOnExit>
                            <div>
                                <div className='shiftwork-modal'>
                                    
                                    <span className='shiftwork-logo-modal' onClick={handleHomeClick}>SH<BoltIcon style={{fontSize: "50px", marginBottom: "12.5px", marginLeft: "-15px", marginRight: "-15px"}}/>FTWORK</span>
                                    <div className='shiftwork-modal-btn-holder'>
                                        <button onClick={handleHomeClick} className='shiftwork-modal-btn'>Home</button>
                                        <button onClick={handleAboutClick} className='shiftwork-modal-btn'>About</button>
                                        <button onClick={handleFeaturesClick} className='shiftwork-modal-btn'>Features</button>
                                        <button onClick={handleIndustriesClick} className='shiftwork-modal-btn'>Industries</button>
                                        <button onClick={handleFAQClick} className='shiftwork-modal-btn'>FAQs</button>
                                        <button style={{width:"90%"}} onClick={handleStartClick} id='shiftwork-button' className='shiftwork-modal-btn-start'>GET STARTED</button>
                                    </div>
                                </div>
                                <div className='shiftwork-close-modal-btn-holder'>
                                    <button className='shiftwork-close-modal-btn' onClick={handleClose}><CloseRoundedIcon style={{fontSize:"30px"}}/></button>
                                </div>
                            </div>
                        </Slide>
                    </Modal>
                </>

            )
            :
            (
                <div className={`shiftwork-nav-desktop ${isScrolled ? 'scrolled' : ''}`}>
                    <div className='shiftwork-nav-title'>
                        <div onClick={handleHomeClick} className={`shiftwork-text-logo-nav ${isScrolled ? 'logo-scrolled' : ''}`}>
                            SH
                            <BoltIcon
                                style={{
                                    transition: "ease-in-out",
                                    transitionDuration: "300ms",
                                    fontSize: isScrolled ? "45px" : (screenSize === "large" ? "55px" : screenSize === "medium" ? "45px" : screenSize === "extra-large" ? "90px" : "60px"),
                                    marginBottom: isScrolled ? "-6px" : (screenSize === "large" ? "-5px" : screenSize === "medium" ? "-5px" : screenSize === "extra-large" ? "-11px" : "-10px"),//bottom
                                    marginLeft: isScrolled ? "-13px" : (screenSize === "large" ? "-16px" : screenSize === "medium" ? "-14px" : screenSize === "extra-large" ? "-27px" : "-12px"), //left
                                    marginRight: isScrolled ? "-14px" : (screenSize === "large" ? "-18px" : screenSize === "medium" ? "-15px" : screenSize === "extra-large" ? "-28px" : "-20px"), //right
                                    // color: screenSize === "large" ? "red" : screenSize === "medium" ? "pink" : screenSize === "extra-large" ? "blue" : "purple" // Example color based on screen size
                                }}
                            />
                            FTWORK
                        </div>
                        {!isScrolled && <div className='shiftwork-nav-subtitle'>Dependable Talent - Lightning Fast</div>}
                    </div>
                    <div id={isScrolled & screenSize === "large" ? "menu-scrolled-large":""} className='shiftwork-nav-menu'>
                        <button onClick={handleAboutClick} className='nav-option'>About</button>
                        <button onClick={handleFeaturesClick} className='nav-option'>Features</button>
                        <button onClick={handleIndustriesClick} className='nav-option'>Industries</button>
                        <button onClick={handleFAQClick} className='nav-option'>FAQs</button>
                        <button onClick={handleStartClick} id='shiftwork-button' className='nav-option'>GET STARTED</button>
                    </div>
                </div>
            )}
    </>)

};

const ShiftworkMainSplash = () => {
    return(
        <div className='shiftwork-main'>

            <video
                autoPlay
                muted
                loop
                playsInline
                className="shiftwork-background-video"
            >
                <source src="/video-three.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className='shiftwork-main-action-holder'>

                <div className='shiftwork-main-action-left'>

                    <button className='shiftwork-main-action-btn'>
                        <div className='shiftwork-main-action-btn-title'>Workers</div>
                        <div className='shiftwork-main-action-btn-value'>1 Worker</div>
                    </button>
               
                    <button className='shiftwork-main-action-btn'>
                        <div className='shiftwork-main-action-btn-title'>Date(s)</div>
                        <div className='shiftwork-main-action-btn-value'>Fri Oct 5</div>
                    </button>
               
                    <button className='shiftwork-main-action-btn'>
                        <div className='shiftwork-main-action-btn-title'>Time</div>
                        <div className='shiftwork-main-action-btn-value'>9:00AM - 5:00PM</div>
                    </button>

                </div>

                <div className='shiftwork-main-action-right'>
                    <button className='shiftwork-main-action-btn-search'>
                        <BoltIcon style={{fontSize:"30px"}}/>
                        <span style={{marginTop:"6px"}}>START</span>
                    </button>
                </div>

            </div>
        </div>
    )
}

const ShiftworkTrustedBy = () => {
    return(
        <div className='shiftwork-partners'>
            <div className='shiftwork-partners-title'>Trusted By</div>
            <div className='shiftwork-partners-firms'>
                <div className='shiftwork-firm'><img src='/pepsi.webp'/></div>
                <div className='shiftwork-firm'><img src='/tbys.webp'/></div>
                <div className='shiftwork-firm'><img src='/logo-ait.webp'/></div>
                <div className='shiftwork-firm'><img style={{height:"60px"}} src='/logo-001.png'/></div>
                <div className='shiftwork-firm'><img src='/integral-hr-logo.svg'/></div>
                <div className='shiftwork-firm'><img src='/Centaur-Security-Logo.png'/></div>
            </div>
        </div>
    )
}

const ShiftworkIndustries = ({signupRef, screenSize}) => {
    const [industryState, setIndustryState] = useState(0);

    const handleStartClick = () => {
        if (signupRef && signupRef.current) {
            signupRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const IndustriesTextInfo = ({num}) => {
        return(
            <>
                {num === 0 ? (
                    <div className='industries-info-text'>
                        <RestaurantRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Restaurants</span>
                        <span className='industries-info-text-subtitle'>Beat the Dinner Rush!</span>
                        <span className='industries-info-text-text'>
                        Keep your restaurant running smoothly during peak hours with our efficient team. Our pre-trained workers are ready to handle the dinner rush, delivering excellent service and support. Focus on delighting your customers—we’ll make sure busy nights stay under control!                        </span>
                        <button onClick={handleStartClick} id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
    
                {num === 1 ? (
                    <div className='industries-info-text'>
                        <WarehouseRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Warehouses</span>
                        <span className='industries-info-text-subtitle'>Power Up Your Packing!</span>
                        <span className='industries-info-text-text'>
                        Boost your warehouse efficiency with our skilled team. Our workers are trained in packing, sorting, and inventory management, ensuring smooth operations during peak times. Focus on productivity—we’ll keep your warehouse running like a well-oiled machine!                        </span>
                        <button onClick={handleStartClick}  id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
    
                {num === 2 ? (
                    <div className='industries-info-text'>
                        <StoreRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Retail</span>
                        <span className='industries-info-text-subtitle'>Stock Up and Shine!</span>
                        <span className='industries-info-text-text'>
                        Keep your retail space ready for customers with our Shiftwork team. Our trained workers manage restocking, customer assistance, and cleanliness, providing reliable support during busy hours to enhance the shopping experience and boost sales!                        </span>
                        <button  onClick={handleStartClick} id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
    
                {num === 3 ? (
                    <div className='industries-info-text'>
                        <SecurityRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Security</span>
                        <span className='industries-info-text-subtitle'>Guard What Matters Most!</span>
                        <span className='industries-info-text-text'>
                        Protect your assets and enjoy peace of mind with our professional security services. Our trained personnel manage various environments, from events to corporate settings, ensuring a secure atmosphere. Trust us to safeguard your premises effectively!                        </span>
                        <button  onClick={handleStartClick} id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
    
                {num === 4 ? (
                    <div className='industries-info-text'>
                        <ConstructionRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Construction</span>
                        <span className='industries-info-text-subtitle'>Build Without Delays!</span>
                        <span className='industries-info-text-text'>
                        Keep your construction projects on track with our reliable workforce solutions. Our skilled workers support your team, ensuring you meet deadlines without sacrificing quality. Trust us to provide the manpower you need for efficient project completion!                        </span>
                        <button onClick={handleStartClick}  id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
    
                {num === 5 ? (
                    <div className='industries-info-text'>
                        <FestivalRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Events</span>
                        <span className='industries-info-text-subtitle'>From Circus to Concert, We’ve Got You Covered!</span>
                        <span className='industries-info-text-text'>
                        Make your events unforgettable with our dedicated support team. From concerts to private gatherings, our skilled professionals handle every detail, ensuring your event runs smoothly. Let us help you create memorable experiences!                        </span>
                        <button onClick={handleStartClick}  id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
                {num === 6 ? (
                    <div className='industries-info-text'>
                        <LocalShippingRoundedIcon style={{fontSize:"80px", color:"#1eaa4a"}}/>
                        <span className='industries-info-text-title'>Transport</span>
                        <span className='industries-info-text-subtitle'>Keep Your Loads Moving Smoothly!</span>
                        <span className='industries-info-text-text'>
                        Ensure timely deliveries with our reliable transport solutions. Our experienced drivers manage everything from local deliveries to long-haul shipments, prioritizing efficiency and safety. Trust us to deliver your goods on time, every time!                        </span>
                        <button  onClick={handleStartClick} id='shiftwork-button' className='industries-info-text-btn'>GET STARTED</button>
                    </div>
                ):(null)}
    
            </>
        )
    }
    

    return(
        <div className='industries-container'>
            <span className='industries-title'>WE'VE GOT YOU COVERED</span>
            <div className='industries-switch-holder'>
                <button onClick={()=>setIndustryState(0)} id={industryState === 0 ?'industries-switch-active':''} className='industries-switch'><RestaurantRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Restaurant"}</button>
                <button onClick={()=>setIndustryState(1)} id={industryState === 1 ?'industries-switch-active':''} className='industries-switch'><WarehouseRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Warehouse"}</button>
                <button onClick={()=>setIndustryState(2)} id={industryState === 2 ?'industries-switch-active':''} className='industries-switch'><StoreRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Retail"}</button>
                <button onClick={()=>setIndustryState(3)} id={industryState === 3 ?'industries-switch-active':''} className='industries-switch'><SecurityRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Security"}</button>
                <button onClick={()=>setIndustryState(4)} id={industryState === 4 ?'industries-switch-active':''} className='industries-switch'><ConstructionRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Construction"}</button>
                <button onClick={()=>setIndustryState(5)} id={industryState === 5 ?'industries-switch-active':''} className='industries-switch'><FestivalRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Events"}</button>
                <button onClick={()=>setIndustryState(6)} id={industryState === 6 ?'industries-switch-active':''} className='industries-switch'><LocalShippingRoundedIcon style={{fontSize:'30px'}}/>{screenSize === "small"?"":"Transport"}</button>
            </div>

            <div className='industries-info-container'>
                <div className='industries-info-image'>
                    <div className='industries-info-image-inner'>
                        {industryState === 0 ? (<img src='/server.jpeg'/>):(null)}
                        {industryState === 1 ? (<img src='/warehouse.jpeg'/>):(null)}
                        {industryState === 2 ? (<img src='/retail.jpeg'/>):(null)}
                        {industryState === 3 ? (<img src='/security.jpg'/>):(null)}
                        {industryState === 4 ? (<img src='/construction.jpg'/>):(null)}
                        {industryState === 5 ? (<img src='/events.jpeg'/>):(null)}
                        {industryState === 6 ? (<img src='/trucker.jpg'/>):(null)}
                    </div>
                </div>
                <IndustriesTextInfo num={industryState}/>
            </div>
        </div>
    )
}


const ShiftworkSectionFiveV2 = ({screenSize}) => {
    const quotes = [
        {
            quote: "“The quality of the freelancers we found through the platform exceeded our expectations.”",
            name: "Maya",
            title: "Real Shiftwork Member",
            icon: "👩‍💼"
        },
        {
            quote: "“Shiftwork helped us save time and made managing shifts effortless!”",
            name: "John",
            title: "Warehouse Night-Shift Manager",
            icon: "👨‍🔧"
        },
        {
            quote: "“The platform is a game-changer for managing large teams with ease.”",
            name: "Sarah",
            title: "Operations Director",
            icon: "👩‍💼"
        },
        {
            quote: "“Thanks to Shiftwork, we can easily find skilled workers whenever we need them.”",
            name: "David",
            title: "HR Coordinator",
            icon: "👨‍💻"
        },
        {
            quote: "“Shiftwork’s platform makes it easy to manage our workforce, even on short notice.”",
            name: "Emily",
            title: "Project Lead",
            icon: "👷‍♀️"
        },
        {
            quote: "“I use Shiftwork almost every week to get last minute drivers. Recommend!”",
            name: "Carlos",
            title: "Logistics Supervisor",
            icon: "🚚"
        },
        {
            quote: "“Shiftwork made it incredibly easy to find reliable security guards on short notice. Highly recommended!”",
            name: "Alex",
            title: "Security Company Owner",
            icon: "🛡️"
        },
        {
            quote: "“With Shiftwork, we can quickly fill gaps in our restaurant staff and keep things running smoothly during busy shifts.”",
            name: "Lily",
            title: "Restaurant Manager",
            icon: "🍽️"
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(false);

    // Handle transitioning between quotes
    const handleNext = () => {
        setFade(true); // Start fade out
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
            setFade(false); // Fade in new quote
        }, 300); // Match this to the CSS transition duration
    };

    const handlePrev = () => {
        setFade(true); // Start fade out
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + quotes.length) % quotes.length);
            setFade(false); // Fade in new quote
        }, 300);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext(); // Auto-switch to the next quote every 5 seconds
        }, 5000);

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        
        <>
        {screenSize === "small" ? 
        (
            <div className='shiftwork-section-five'>
                <div className='shiftwork-specials-card-holder'>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[0].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[0].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[0].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[0].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[1].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[1].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[1].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[1].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[6].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[6].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[6].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[6].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[7].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[7].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[7].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[7].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[2].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[2].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[2].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[2].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[3].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[3].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[3].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[3].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[4].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[4].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[4].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[4].title}</div>
                    </div>

                    <div className='shiftwork-specials-card'>
                        <span className='shiftwork-specials-card-quote'>{quotes[5].quote}</span>
                        <div className='shiftwork-specials-card-icon'>{quotes[5].icon}</div>
                        <div className='shiftwork-specials-card-name'>{quotes[5].name}</div>
                        <div className='shiftwork-specials-card-role'>{quotes[5].title}</div>
                    </div>

               

                </div>

            </div>
        )
        :
        (
            <div className='shiftwork-section-five'>
                <button className='shiftwork-section-five-arrow' onClick={handlePrev}>
                    <ArrowBackIosRoundedIcon style={{ fontSize: "35px" }} />
                </button>
                <div className='shiftwork-section-five-info-holder'>
                    <div className={`shiftwork-section-five-quote ${fade ? 'fade' : ''}`}>{quotes[currentIndex].quote}</div>
                    <div className={`shiftwork-section-five-icon ${fade ? 'fade' : ''}`}>{quotes[currentIndex].icon}</div>
                    <div className={`shiftwork-section-five-name ${fade ? 'fade' : ''}`}>{quotes[currentIndex].name}</div>
                    <div className={`shiftwork-section-five-title ${fade ? 'fade' : ''}`}>{quotes[currentIndex].title}</div>
                </div>
                <button className='shiftwork-section-five-arrow' onClick={handleNext}>
                    <ArrowForwardIosRoundedIcon style={{ fontSize: "35px" }} />
                </button>
            </div>
        )
        }    
        </>
    );
}

const ShiftworkSub = () => {
    
    const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();

        console.log("testing ")

        const serviceID = 'service_s6c3686';
        const templateID = 'template_j0t7xc3'
        const userID = '9cAI0Tyx13h32lrEs';

        try {
            await emailjs.sendForm(serviceID, templateID, e.target, userID);
            alert('Sent successfully!');
        } catch (error) {
            alert('An error occurred while sending the email.');
            console.error(error);
        } 
    };

    return(
        <div className='shiftwork-sub'>
            <div className='shiftwork-sub-inner'>
                {/* <span className='shiftwork-sub-title'>Optimize Your Workforce!</span> */}
                <span className='shiftwork-sub-title'>OPTIMIZE YOUR WORKFORCE!</span>
                <span className='shiftwork-sub-subtitle'>Sign up today and receive a free PDF guide on optimizing your workforce!</span>
                <form onSubmit={handleGeneralEmailSubmit} className='shiftwork-sub-input-holder'>
                    <input placeholder="Your Email" className='shiftwork-sub-input' name='pdf_req_email'></input>
                    <button id='shiftwork-button' className='shiftwork-sub-btn'>SUBMIT</button>
                </form>
            </div>
        </div>
    )
}

const ShiftworkFooter = ({ screenSize }) => {
    return(
        <div className='shiftwork-footer'>

            <div className='shiftwork-footer-bottom'>
                <div style={{color:"white"}} className='shiftwork-text-logo-footer'>
                    SH<BoltIcon style={{ transition: "ease-in-out", transitionDuration: "250ms", fontSize: screenSize === "small" ? "50px" : "55px", marginLeft: "-15px", marginBottom: "-6px", marginRight: "-17px" }} />FTWORK
                </div>
                <div className='shiftwork-footer-bottom-right'>
                    <div className='shiftwork-footer-bottom-right-row'>
                        <button className='shiftwork-social'><EmailRoundedIcon/>info@shiftwork.com</button>
                        <button className='shiftwork-social'><LocalPhoneRoundedIcon/>+1 (416) 494-9090</button>
                        <button className='shiftwork-social'><WhatsAppIcon/>+1 (416) 494-9090</button>
                    </div>
                    <div className='shiftwork-footer-bottom-right-row'>
                        <button className='shiftwork-social'><InstagramIcon/>@GetShiftwork</button>
                        <button className='shiftwork-social'><FacebookIcon/>@GetShiftwork</button>
                        <button className='shiftwork-social'><TikTokIcon/>@GetShiftwork</button>
                    </div>
                </div>
            </div>


        </div>
    )
}

const ShiftworkFooter2 = ({screenSize}) => {
    return(
        <div style={{background:"white", height:"600px"}} className='shiftwork-footer'>

            <div style={{background:"white"}} className='shiftwork-footer-bottom'>
                <div  style={{color:"#1eaa4a", fontSize:"400px"}} className='shiftwork-text-logo-footer'>
                    SH
                    <BoltIcon style={{ fontSize: "390px", marginLeft: "-92.5px", marginBottom:"-57px", marginRight: "-160px" }} />
                    FTWORK
                </div>
            </div>


        </div>
    )
}


const ShiftworkFAQ = () => {
    
    return(
        <div className='shiftwork-faq-container'>
            
            <span className='shiftwork-faq-title'>Questions? We've got Answers!</span>
            
            <div className='shiftwork-faq-inner-container'>
                <div className='shiftwork-faq-inner-container-img'><img src='/training2.jpeg'/></div>
                <div className='shiftwork-faq-inner-container-txt'>
                    <span className='shiftwork-faq-inner-container-txt-q'>How do you ensure workers are trained?</span>
                    <span className='shiftwork-faq-inner-container-txt-a'>We ensure all workers complete a mandatory training program tailored to their roles. If you provide additional training documentation or videos, workers must also complete this content before their arrival, ensuring they are fully prepared from day one.</span>
                </div>
            </div>


            <div className='shiftwork-faq-inner-container'>
                <div className='shiftwork-faq-inner-container-img'><img src='/qualified.jpeg'/></div>
                <div className='shiftwork-faq-inner-container-txt'>
                    <span className='shiftwork-faq-inner-container-txt-q'>What qualifications do workers have?</span>
                    <span className='shiftwork-faq-inner-container-txt-a'>All workers are pre-trained and thoroughly vetted, possessing the necessary qualifications and experience required for their roles. Our stringent selection process ensures that only the most qualified candidates are matched to your needs.</span>
                </div>
            </div>


            <div className='shiftwork-faq-inner-container'>
                <div className='shiftwork-faq-inner-container-img'><img src='/matched.jpg'/></div>
                <div className='shiftwork-faq-inner-container-txt'>
                    <span className='shiftwork-faq-inner-container-txt-q'>How quickly can workers be matched?</span>
                    <span className='shiftwork-faq-inner-container-txt-a'>Shiftwork matches workers to your needs in as little as one minute. Our efficient matching system leverages advanced technology to ensure prompt placements, minimizing downtime for your operations.</span>
                </div>
            </div>


            <div className='shiftwork-faq-inner-container'>
                <div className='shiftwork-faq-inner-container-img'><img src='security-check.jpg'/></div>
                <div className='shiftwork-faq-inner-container-txt'>
                    <span className='shiftwork-faq-inner-container-txt-q'>What security clearances do workers undergo?</span>
                    <span className='shiftwork-faq-inner-container-txt-a'>All workers undergo substantial security clearances as part of our hiring process. This includes background checks and verification of credentials to ensure compliance with industry standards and provide you with peace of mind.</span>
                </div>
            </div>


            <div className='shiftwork-faq-inner-container'>
                <div className='shiftwork-faq-inner-container-img'><img src='/reliability.jpeg'/></div>
                <div className='shiftwork-faq-inner-container-txt'>
                    <span className='shiftwork-faq-inner-container-txt-q'>How do you ensure worker reliability?</span>
                    <span className='shiftwork-faq-inner-container-txt-a'>We prioritize worker reliability by conducting thorough background checks and ongoing performance evaluations. Additionally, we maintain open communication channels for feedback, ensuring a high standard of professionalism and accountability.</span>
                </div>
            </div>


        </div>
    )
}

const ShiftworkAbout = ({screenSize}) => {
    const [inView, setInView] = useState(false);
    const missionRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.unobserve(missionRef.current); // Stop observing after animation triggers
          }
        },
        { threshold: 0.5 }
      );
  
      if (missionRef.current) {
        observer.observe(missionRef.current);
      }
  
      return () => {
        if (missionRef.current) {
          observer.unobserve(missionRef.current);
        }
      };
    }, []);
  
    return (
    <div className='shiftwork-about-holder'>
        <div className='shiftwork-about-mission-holder' ref={missionRef}>
            <div className={`shiftwork-about-mission-statement ${inView ? "in-view" : ""}`}>
            <span className='shiftwork-mission-statement'>
                <span className={`ms-word1 ${inView ? "animate" : ""}`}>
                    SH
                    <BoltIcon style={{ 
                        fontSize: 
                            screenSize === "small" ? "55px" : 
                            screenSize === "medium" ? "95px" : "105px", 

                        marginLeft: 
                            screenSize === "small" ? "-14px" : 
                            screenSize === "medium" ? "-24px" : "-28px", 

                        marginBottom: 
                            screenSize === "small" ? "-6px" : 
                            screenSize === "medium" ? "-13px" : "-15px", 

                        marginRight: 
                            screenSize === "small" ? "-16px" : 
                            screenSize === "medium" ? "-30px" : "-31px" 
                        }}
                    />
                    FTWORK
                </span>
                <span className={`ms-word2 ${inView ? "animate" : ""}`}> FILLS</span>
                <span className={`ms-word3 ${inView ? "animate" : ""}`}> LAST-MINUTE</span>
                <span className={`ms-word4 ${inView ? "animate" : ""}`}> AND</span>
                <span className={`ms-word5 ${inView ? "animate" : ""}`}> NO-SHOW</span>
                <span className={`ms-word6 ${inView ? "animate" : ""}`}> SHIFTS</span>
                <span className={`ms-word7 ${inView ? "animate" : ""}`}> IN</span>
                <span className={`ms-word8 ${inView ? "animate" : ""}`}> MINUTES!</span>                                 
            </span>
            </div>
        </div>

        <div className='shiftwork-about-mission-bottom'>
            <div className={`shiftwork-about-mission-card ${inView ? "animate" : ""}`}>
                <div className='shiftwork-about-mission-card-img'><img src='/about-pic-1.jpeg'/></div>
                <span className='shiftwork-about-mission-card-title'>Fast</span>
                <span className='shiftwork-about-mission-card-text'>Lightning-fast placements with our advanced matching system, ensuring you have the right workers in a minute.</span>
            </div>

            <div className={`shiftwork-about-mission-card ${inView ? "animate" : ""}`}>
                <div className='shiftwork-about-mission-card-img'><img src='/about-pic-3.jpeg'/></div>
                <span className='shiftwork-about-mission-card-title'>Dependable</span>
                <span className='shiftwork-about-mission-card-text'>Built on reliability, thorough vetting and consistent training, we deliver high-quality performance on every job.</span>
            </div>

            <div className={`shiftwork-about-mission-card ${inView ? "animate" : ""}`}>
                <div className='shiftwork-about-mission-card-img'><img src='/about-pic-2.jpg'/></div>
                <span className='shiftwork-about-mission-card-title'>Safe</span>
                <span className='shiftwork-about-mission-card-text'>Safety is our top priority. All workers undergo comprehensive security clearances and training.</span>
            </div>

        </div>
    </div>
    );
};





const ShiftworkTrustFactorsV3 = ({signupRef, screenSize}) => {

    const handleStartClick = () => {
        if (signupRef && signupRef.current) {
            signupRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const titles = [
        "Matched in 1 Minute!",
        "Pre-Trained & Vetted!",
        "Secure & Safe!",
        "Years of Experience!"
    ];

    const texts = [
        `Our cutting edge Algorithms ensure your workers will be matched to your positions in just one minute! We ensure quick placements and minimize your downtime! `,
        `Pre-trained and thoroughly vetted, ready to jump into action! Send training modules and assessments to workers and reduce your onboarding times by 90%.`,
        `Every worker undergoes thorough security clearances, providing peace of mind and ensuring compliance with industry standards.`,
        `Our workforce consists of professionals with 3-5 years of experience in their respective fields, ensuring high-quality performance and expertise.`
    ];



    return (
        
        <div className='shiftwork-trust-factors-v2'>

            <div className='shiftwork-trust-factors-v2-title'>
                WHY 
                {screenSize === "small" || screenSize === "medium" ? (<br/>):(<span>  </span>)}
                 SH
                <BoltIcon style={{ color: "#1eaa4a", fontSize: screenSize ==="small" ? "49px" : "85px", marginLeft: screenSize ==="small" ? "-14px" : "-23px", marginBottom: screenSize ==="small" ? "-7px" : "-12px", marginRight: screenSize ==="small" ? "-15px" : "-25px" }} />
                FTWORK?
            </div>

            <div className='shiftwork-trust-factors-v2-lower'>
                <div className='shiftwork-trust-factors-v2-left'>
                    <div className='shiftwork-trust-factors-v2-left-inner'>
                        <div className='shiftwork-trust-factors-v2-left-title'><span>{titles[0]}</span></div>
                        <div className='shiftwork-trust-factors-v2-left-text'><span>{texts[0]}</span></div>
                        <button onClick={handleStartClick} id='shiftwork-button' className='shiftwork-trust-factors-v2-left-btn'>GET STARTED</button>
                    </div>
                </div>
                <div className='shiftwork-trust-factors-v2-right'><img src='/quick-n-easy.jpeg'/></div>
            </div>



            <div className='shiftwork-trust-factors-v2-lower'>
                {screenSize === "small" || screenSize === "medium" ? (null):(<div className='shiftwork-trust-factors-v2-right'><img src='/training.jpeg'/></div>)}
                <div style={screenSize === "small" || screenSize === "medium" ? null:{ marginLeft: "50px" } } className='shiftwork-trust-factors-v2-left'>
                    <div className='shiftwork-trust-factors-v2-left-inner'>
                        <div className='shiftwork-trust-factors-v2-left-title'><span>{titles[1]}</span></div>
                        <div className='shiftwork-trust-factors-v2-left-text'><span>{texts[1]}</span></div>
                        <button onClick={handleStartClick} id='shiftwork-button' className='shiftwork-trust-factors-v2-left-btn'>GET STARTED</button>
                    </div>
                </div>
                {screenSize === "small" || screenSize === "medium" ? (<div className='shiftwork-trust-factors-v2-right'><img src='/training.jpeg'/></div>):(null)}
            </div>



            <div className='shiftwork-trust-factors-v2-lower'>
                <div className='shiftwork-trust-factors-v2-left'>
                    <div className='shiftwork-trust-factors-v2-left-inner'>
                        <div className='shiftwork-trust-factors-v2-left-title'><span>{titles[2]}</span></div>
                        <div className='shiftwork-trust-factors-v2-left-text'><span>{texts[2]}</span></div>
                        <button onClick={handleStartClick} id='shiftwork-button' className='shiftwork-trust-factors-v2-left-btn'>GET STARTED</button>
                    </div>
                </div>
                <div className='shiftwork-trust-factors-v2-right'><img src='/safe-secure.jpeg'/></div>
            </div>



            <div className='shiftwork-trust-factors-v2-lower'>
                {screenSize === "small" || screenSize === "medium" ? (null):(<div className='shiftwork-trust-factors-v2-right'><img src='/skilled-worker.jpeg'/></div>)}
                <div style={screenSize === "small" || screenSize === "medium" ? null:{ marginLeft: "50px" } } className='shiftwork-trust-factors-v2-left'>
                    <div className='shiftwork-trust-factors-v2-left-inner'>
                        <div className='shiftwork-trust-factors-v2-left-title'><span>{titles[3]}</span></div>
                        <div className='shiftwork-trust-factors-v2-left-text'><span>{texts[3]}</span></div>
                        <button onClick={handleStartClick} id='shiftwork-button' className='shiftwork-trust-factors-v2-left-btn'>GET STARTED</button>
                    </div>
                </div>
                {screenSize === "small" || screenSize === "medium" ? (<div className='shiftwork-trust-factors-v2-right'><img src='/skilled-worker.jpeg'/></div>):(null)}
            </div>




        </div>
    );
};





const ShiftworkSignup = ({screenSize}) => {
    const [hireSwitch, setHireSwitch] = useState(true);

    const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();

        console.log("testing ")

        const serviceID = 'service_s6c3686';
        const templateID = 'template_icujpqm'
        const userID = '9cAI0Tyx13h32lrEs';

        try {
            await emailjs.sendForm(serviceID, templateID, e.target, userID);
            alert('Sent successfully!');
        } catch (error) {
            alert('An error occurred while sending the email.');
            console.error(error);
        } 
    };


    return(

        <div className='shiftwork-signup-container'>
            <div className='shiftwork-signup-title'>
                SIGN UP NOW!
            </div>

            <div className='shiftwork-signup-switch-container'>
                <button onClick={()=>setHireSwitch(true)} id={hireSwitch ? 'signup-switch-active':''} className='shiftwork-signup-switch'>HIRE NOW</button>
                <button onClick={()=>setHireSwitch(false)} id={!hireSwitch ? 'signup-switch-active':''} className='shiftwork-signup-switch'>START WORKING</button>
            </div>
            
            <div className='shiftwork-signup-inner-container'>
                {hireSwitch?
                    (<>
                        <div className='shiftwork-signup-inner-right'>
                            <form onSubmit={handleGeneralEmailSubmit}>
                                <span className='shiftwork-signup-inner-right-title'>SHIFT THE WAY YOU HIRE!</span>
                                <span className='shiftwork-signup-inner-right-subtitle'>Hire in 1 Minute!</span>
                                <div className='shiftwork-signup-inner-right-input-holder'>
                                    <input style={{display:"none"}} value="ORG-SIGN-UP" name='request_type'/>
                                    <input type="text" placeholder="First Name" required className='input-field' name="org_first_name" />
                                    <input type="text" placeholder="Last Name" required className='input-field' name="org_last_name"/>
                                    <input type="text" placeholder="Business Name" required className='input-field' name="org_business_name"/>
                                    <input type="email" placeholder="Email" required className='input-field' name="org_email"/>
                                    <input type="tel" placeholder="Phone Number" required className='input-field' name="org_phone_num"/>
                                    
                                    <select required className='input-field' name="org_industry">
                                        <option value="" disabled selected>Select Industry</option>
                                        <option value="Warehouse">Warehouse</option>
                                        <option value="Restaurant">Restaurant</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Security">Security</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Events">Events</option>
                                        <option value="Transport">Transport</option>
                                        <option value="Healthcare">Healthcare</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <button id='shiftwork-button' className='shiftwork-signup-inner-right-btn'>HIRE NOW</button>
                            </form>
                        </div>
                    </>)
                    :
                    (<>
                        <div className='shiftwork-signup-inner-right'>
                            <form onSubmit={handleGeneralEmailSubmit}>
                                <span className='shiftwork-signup-inner-right-title'>READY, SET, WORK!</span>
                                <span className='shiftwork-signup-inner-right-subtitle'>Start Working Now!</span>
                                <div className='shiftwork-signup-inner-right-input-holder'>
                                    <input style={{display:"none"}} value="JOBBER-SIGN-UP" name='request_type'/>
                                    <input type="text" placeholder="First Name" required className='input-field'  name="jobber_first_name" />
                                    <input type="text" placeholder="Last Name" required className='input-field' name="jobber_last_name"/>
                                    <input type="text" placeholder="Email" required className='input-field'  name="jobber_email"/>
                                    <input type="tel" placeholder="Phone Number" required className='input-field'  name="jobber_phone_num"/>
                                    <input type="number" placeholder="Years of Work Experience" required className='input-field'  name="jobber_num_work_years"/>
                                </div>
                                <button id='shiftwork-button' className='shiftwork-signup-inner-right-btn'>START WORKING</button>
                            </form>
                        </div>
                    </>)
                }
            </div>

        </div>
    )
}


const FooterTest = () => {


    return(
        <div className='testfooter'>
            <div className='test-shiftwork'>SH<BoltIcon style={{fontSize:"120px", marginBottom:"-15px", marginLeft:"-32.5px", marginRight:"-35px"}}/>FTWORK</div>
        </div>
    )
}



const Shiftwork = () => {
    const aboutRef = useRef(null);
    const featuresRef = useRef(null);
    const industriesRef = useRef(null);
    const faqRef = useRef(null);
    const signupRef = useRef(null);


    const [screenSize, setScreenSize] = useState('');
    const checkScreenWidth = () => {
        const width = window.innerWidth;

        if (width <= 768) {
            setScreenSize('small'); // Corresponds to max-width: 768px
        } else if (width >= 768 && width <= 934) {
            setScreenSize('medium'); // Corresponds to min-width: 840px and max-width: 979px
        } else if (width >= 935 && width <= 1169) {
            setScreenSize('large'); // Corresponds to min-width: 980px and max-width: 1169px
        }else {
            setScreenSize('extra-large');
        }
    };

    useEffect(() => {
        checkScreenWidth();
        window.addEventListener('resize', checkScreenWidth);
        return () => {window.removeEventListener('resize', checkScreenWidth);};
    }, []);



    return (
        <>
            <ShiftworkNav
                aboutRef={aboutRef}
                featuresRef={featuresRef}
                industriesRef={industriesRef}
                faqRef={faqRef}
                signupRef={signupRef}
                screenSize={screenSize}
            />
            <ShiftworkMainSplash />
            
            <ShiftworkTrustedBy />

            <div ref={aboutRef}>
                <ShiftworkAbout screenSize={screenSize} />
            </div>

            <ShiftworkSub/>

            <div ref={featuresRef}>
                <ShiftworkTrustFactorsV3 signupRef={signupRef} screenSize={screenSize}/>
            </div>

            <ShiftworkSectionFiveV2  screenSize={screenSize} />
            
            <div ref={industriesRef}>
                <ShiftworkIndustries signupRef={signupRef} screenSize={screenSize}/>
            </div>
            
            <div ref={signupRef}>
                <ShiftworkSignup screenSize={screenSize} />
            </div>
            
            <div ref={faqRef}>
                <ShiftworkFAQ />
            </div>
            
            <ShiftworkFooter screenSize={screenSize} />

            {/* <FooterTest/> */}
        </>
    );
};





export { Shiftwork }